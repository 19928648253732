
  import { defineComponent, onMounted, ref, nextTick } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { useAbility } from '@casl/vue';
  import * as Yup from 'yup';
  import Multiselect from '@vueform/multiselect';

  export default defineComponent({
    name: 'ads-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
      Multiselect,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const selectedStatus = ref<number>();
      const route = useRoute();
      const { can } = useAbility();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const rejection = ref('');
      const rejectionNote = ref('');

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('adsUpdating'), [translate('ads')]);
      });

      const { data } = await store.dispatch(Actions.GET_AD, route.params.id);

      const ad = ref(data.data);

      const translate = (text: string) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const adsSchema = Yup.object().shape({
        isApproved: Yup.number()
          .required(() => translate('ACTIVE_STATUS_IS_REQUIRED_FIELD'))
          .label('Active Status')
          .nullable(),
        rejectionNote: Yup.string().when('isApproved', {
          is: 0,
          then: Yup.string()
            .required(() => translate('REJECTION_NOTE_IS_REQUIRED_FIELD'))
            .label('Rejection Note'),
        }),
      });

      const onSubmitUpdate = async (values) => {
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        const payload = {
          isApproved: values.isApproved,
          rejectionNote: values.rejectionNote,
        };
        await store.dispatch(Actions.UPDATE_AD_STATUS, {
          id: ad.value?.id,
          data: payload,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_ADS_REQUEST'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'ads-listing' });

            // router.push({
            //   name: 'ads-viewing',
            //   params: { id: ad.value?.id },
            // });
          });
        }
      };

      const rejectReasonsData = await store.dispatch(
        Actions.GET_REJECT_ADS_REASON
      );

      const rejectReasons = rejectReasonsData.data.data;

      const updaterejectionNote = async () => {
        await nextTick();
        if (rejection.value) rejectionNote.value = rejection.value;
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        onSubmitUpdate,
        selectedStatus,
        submitButton,
        translate,
        adsSchema,
        goBack,
        ad,
        can,
        rejection,
        rejectionNote,
        updaterejectionNote,
        rejectReasons,
      };
    },
  });
